import React from "react";
import { FaTimes } from "react-icons/fa";

function BookDemoModal({ show, setShow }) {
  if (!show) {
    return null;
  }
  return (
    <div className="bg-gray-800 bg-opacity-40 fixed inset-0">
      <div className="flex justify-end pt-8 pr-8">
        <FaTimes onClick={() => setShow(false)} size={24} color="white" />
      </div>
      <iframe
        className="w-4/5 h-4/5 mx-auto mt-20"
        src="https://arkangel-1.hubspotpagebuilder.com/landingarkangelai?embed_domain=arkangel.ai&embed_type=PopupText"
      />
    </div>
  );
}

export default BookDemoModal;
