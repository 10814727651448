import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { BookDemoModal } from "..";

function ButtonBookDemo() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  return (
    <>
      <button
        onClick={handleClick}
        className="calendly-inline-widget text-white bg-primary px-4 py-3 rounded-xl font-bold text-sm md:text-base"
      >
        {t("buttons.book_a_demo")}
      </button>
      <BookDemoModal show={show} setShow={setShow} />
    </>
  );
}

export default ButtonBookDemo;
