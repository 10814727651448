import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BurgerMenu, ButtonBookDemo } from "..";
import ArkangelLogo from "../../assets/images/arkangel-logo.png";
import Dropdown from "../../utils/Dropdown";

function Header() {
  const { i18n, t } = useTranslation();
  return (
    <header className="flex justify-between max-w-6xl mx-auto pt-3 items-center text-sm">
      <Link to="/">
        <figure style={{ maxWidth: "220px", minWidth: "180px" }}>
          <img src={ArkangelLogo} alt="logo arkangel ai" />
        </figure>
      </Link>
      <ul className="hidden md:flex">
        <li className="mx-5">
          <Link to="/templates">{t("header.templates")}</Link>
        </li>
        <li className="mx-5">
          <Link to="/pricing">{t("header.pricing")}</Link>
        </li>
        <Dropdown title="More">
          {/* 2nd level: hover */}
          <li>
            <a
              href="https://blog.arkangel.ai"
              target="_blank"
              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
            >
              Blog
            </a>
          </li>
          <li>
            <a
              href="https://github.com/arkangelai"
              target="_blank"
              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
            >
              {t("header.documentation")}
            </a>
          </li>
          <li>
            <a
              href="https://www.notion.so/Trabaja-con-nosotros-386d9c038a544950867e353c22b89cbb"
              target="_blank"
              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
            >
              {t("header.work_with_us")}
            </a>
          </li>
          <li>
            <a
              href="https://ark.kampsite.co/"
              target="_blank"
              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
            >
              {t("header.suggest_an_algorithm")}
            </a>
          </li>
          <li>
            <a className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">
              {t("header.contact_us")}
            </a>
          </li>
        </Dropdown>
        <li
          onClick={() => {
            localStorage.setItem("lang", i18n.language === "es" ? "en" : "es");
            i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
          }}
          className="mx-5 cursor-pointer"
        >
          <img
            width="35px"
            src={
              i18n.language === "es"
                ? require("../../assets/images/spanish-flag.png")
                : require("../../assets/images/english-flag.jpeg")
            }
          />
        </li>
      </ul>
      <div className="hidden md:flex">
        <ButtonBookDemo />
      </div>
      <BurgerMenu />
    </header>
  );
}

export default Header;
