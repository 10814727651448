export const templatesData = [
  {
    id: 1,
    image: require("../assets/images/investigation-malaria.png"),
    title: "Malaria falciparum and vivax detection",
    description: "Microscope AI assistant for detection of malaria parasites.",
    author: "Arkangel AI",
    category: "parasitary",
  },
  {
    id: 2,
    image: require("../assets/images/investigation-ocular.png"),
    title: "Diabetic retinopathy detection",
    description: "Fundus images AI assistant for ophthalmologists.",
    author: "Clínica Barraquer & Arkangel AI",
    category: "ocular",
  },
  {
    id: 3,
    image: require("../assets/images/investigation-respiratory.png"),
    title: "Chest x-ray pathology detection",
    description:
      "X-ray AI assistant for emergency department physicians and radiologists.",
    author: "Arkangel AI",
    category: "respiratory",
  },
  {
    id: 4,
    image: require("../assets/images/template-acute.png"),
    title: "Acute Lymphoblastic Leukemia detection",
    description:
      "Microscope AI assistant for detection of ALLeukemia in blood and bone marrow samples.",
    author: "Arkangel AI",
    category: "oncology",
  },
  {
    id: 5,
    image: require("../assets/images/UCI-Admission.png"),
    title: "ICU admission prediction",
    description:
      "Hospital AI assistant for detection ICU patients in COVID-19 zones.",
    author: "Clínica de la Sabana & Arkangel AI",
    category: "hospital optimization",
  },
];
