import React from "react";
import ArkangelLogo from "../../assets/images/arkangel-logo.png";
import {
  FaWhatsappSquare,
  FaTwitterSquare,
  FaLinkedin,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <footer className="bg-blackBackground text-white pt-24 pb-10 relative z-10">
      <div className="max-w-5xl mx-auto px-6">
        <div className="block md:flex justify-between mb-10 items-center">
          <figure className="w-3/5 md:w-1/5 pb-4 md:pb-0">
            <img src={ArkangelLogo} />
          </figure>
          <p className="max-w-xs block md:hidden font-bold text-xl md:text-2xl mb-6 md:mb-0">
            {t("footer.mission")}
          </p>
          <ul className="flex">
            <li
              onClick={() => {
                localStorage.setItem(
                  "lang",
                  i18n.language === "es" ? "en" : "es"
                );
                i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
              }}
              className="mx-4 cursor-pointer"
            >
              <img
                width="40px"
                src={
                  i18n.language === "es"
                    ? require("../../assets/images/spanish-flag.png")
                    : require("../../assets/images/english-flag.jpeg")
                }
              />
            </li>
            <li className="mx-2">
              <FaWhatsappSquare size={25} />
            </li>
            <li className="mx-2">
              <a href="https://twitter.com/arkangelai" target="_blank">
                <FaTwitterSquare size={25} />
              </a>
            </li>
            <li className="mx-2">
              <a
                href="https://www.linkedin.com/company/arkangel-ai/"
                target="_blank"
              >
                <FaLinkedin size={25} />
              </a>
            </li>
            <li className="mx-2">
              <a href="https://www.instagram.com/arkangel.ai/" target="_blank">
                <FaInstagramSquare size={25} />
              </a>
            </li>
            <li className="mx-2">
              <a
                href="https://www.youtube.com/channel/UCUfpSsTRUnVZBkAO4EYCiAA/featured"
                target="_blank"
              >
                <FaYoutubeSquare size={25} />
              </a>
            </li>
          </ul>
        </div>
        <div className="flex text-sm">
          <p className="max-w-xs font-bold text-2xl hidden md:block">
            {t("footer.mission")}
          </p>
          <div className="grid gap-6 grid-cols-2 md:grid-cols-4 w-full md:ml-12 md:justify-items-center">
            <div>
              <p className="text-gray-500">{t("footer.explore.explore")}</p>
              <ul className="text-sm">
                <a href="https://app.arkangel.ai/login" className="my-1 block">
                  {t("footer.explore.login")}
                </a>
                <a
                  href="https://app.arkangel.ai/register"
                  className="my-1 block"
                >
                  {t("footer.explore.register")}
                </a>
                <a className="my-1 block">{t("footer.explore.contact")}</a>
                <a className="my-1 block">{t("footer.explore.about")}</a>
                <a
                  href="https://www.notion.so/Trabaja-con-nosotros-386d9c038a544950867e353c22b89cbb"
                  className="my-1 block"
                >
                  {t("footer.explore.work_with_us")}
                </a>
                <a
                  href="https://www.notion.so/Arkangel-Library-f2b7b6432d3e4632b2bc94826d05230b"
                  className="my-1 block"
                >
                  {t("footer.explore.ark_library")}
                </a>
                <a href="https://blog.arkangel.ai" className="my-1 block">
                  {t("footer.explore.blog")}
                </a>
              </ul>
            </div>
            <div>
              <p className="text-gray-500">{t("footer.support.support")}</p>
              <ul className="text-sm">
                <a href="https://github.com/arkangelai" className="my-1 block">
                  {t("footer.support.documentation")}
                </a>
                <a href="https://ark.kampsite.co/" className="my-1 block">
                  {t("footer.support.suggest_a_feature")}
                </a>
              </ul>
            </div>
            <div>
              <p className="text-gray-500">{t("footer.legal.legal")}</p>
              <ul className="text-sm">
                <a className="my-1 block">
                  {t("footer.legal.terms_and_conditions")}
                </a>
                <a className="my-1 block">
                  {t("footer.legal.privacy_policies")}
                </a>
                <a className="my-1 block">
                  {t("footer.legal.cookies_declaration")}
                </a>
              </ul>
            </div>
            <div>
              <p className="text-gray-500">
                {t("footer.contact_us.contact_us")}
              </p>
              <ul className="text-sm">
                <li className="my-1">
                  1250 Guy St Suite #600, Montreal, QC, Canada
                </li>
                <li className="my-1 mt-5">3107791950</li>
                <li className="my-1">hola@arkangel.ai</li>
              </ul>
            </div>
          </div>
        </div>
        <p className="text-gray-500 text-sm mt-8">
          © 2020 Arkangel AI, all rights reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;
