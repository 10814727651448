import React, { useEffect, useRef, useState } from "react";
import { IoMdMenu, IoMdClose } from "react-icons/io";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function useOutsideAlerter(ref, action) {
  useEffect(() => {
    /**
     * change state if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function BurgerMenu({ children, dataApi }) {
  const [show, setShow] = useState(false);
  const [transition, setTransition] = useState(false);
  const { t, i18n } = useTranslation();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setTransition);

  return (
    <>
      <div className="block md:hidden">
        {show && (
          <div
            className="bg-gray-400 fixed top-0 left-0 bottom-0 bg-opacity-20 z-10"
            style={{ width: "100vw" }}
          >
            <CSSTransition
              classNames="burgerMenu"
              in={transition}
              appear={transition}
              onExited={() => setShow(false)}
              timeout={400}
            >
              <div
                ref={wrapperRef}
                className="bg-white h-full w-4/5 md:w-96 pt-4 shadow absolute right-0 top-0 bottom-0"
              >
                <IoMdClose
                  onClick={() => setTransition(!transition)}
                  className="ml-auto mr-8 cursor-pointer"
                  size={26}
                />
                <ul>
                  <li className="mx-5 text-xl my-3 text-center">
                    <Link to="/templates">{t("header.templates")}</Link>
                  </li>
                  <li className="mx-5 text-xl my-3 text-center">
                    <Link to="/pricing">{t("header.pricing")}</Link>
                  </li>
                  <li className="mx-5 text-xl my-3 text-center">
                    {t("header.more")}
                  </li>
                  <li
                    onClick={() => {
                      localStorage.setItem(
                        "lang",
                        i18n.language === "es" ? "en" : "es"
                      );
                      i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
                    }}
                    className="mx-5 cursor-pointer"
                  >
                    <img
                      width="40px"
                      className="mx-auto"
                      src={
                        i18n.language === "es"
                          ? require("../../assets/images/spanish-flag.png")
                          : require("../../assets/images/english-flag.jpeg")
                      }
                    />
                  </li>
                </ul>
              </div>
            </CSSTransition>
          </div>
        )}
        <div
          onClick={() => {
            setShow(true);
            setTransition(true);
          }}
        >
          <IoMdMenu
            color="black"
            size="26"
            className="text-gray-400 cursor-pointer"
          />
        </div>
      </div>
      {children}
    </>
  );
}

export default BurgerMenu;
