import React from "react";
import { Link } from "react-router-dom";

function TemplateItem({ image, title, description, author, id }) {
  return (
    <Link
      to={{
        pathname: `/template/${id}`,
      }}
      className="bg-mainGray rounded-xl overflow-hidden"
    >
      <figure>
        <img className="max-h-40 w-full object-cover" src={image} />
      </figure>
      <div className="mx-6 mt-2 mb-5">
        <p className="font-bold">{title}</p>
        <p className="text-green-500 text-xs mt-1 mb-3">by {author}</p>
        <p className="text-sm">{description}</p>
      </div>
    </Link>
  );
}

export default TemplateItem;
