import React, { useState, useEffect } from "react";
import { ButtonBookDemo, Footer, Header, TemplateItem } from "../../components";
import { templatesData } from "../../utils/templatesData";

function Template() {
  const [templateData, setTemplateData] = useState({});
  useEffect(() => {
    const templateId = parseInt(window.location.pathname.split("/")[2]);
    const template = templatesData.find(
      (template) => parseInt(template.id) === parseInt(templateId)
    );
    setTemplateData(template);
  }, [window]);
  console.log(templateData);
  return (
    <>
      <div className="bg-mainGray px-2 m-4 pb-3 rounded-3xl">
        <Header />
      </div>
      <section className="max-w-6xl mx-auto mb-16 px-5 lg:px-0">
        <p className="text-sm text-gray-500 mt-8">templates &gt; Parasitary</p>
        <div className="md:flex">
          <figure
            style={{ backgroundColor: "#ECF6EC" }}
            className="w-full py-4 mb-4 md:mb-0 md:w-3/5 mr-10 flex items-center rounded-2xl"
          >
            <img
              className="w-4/5 mx-auto rounded-xl"
              src={templateData.image}
            />
          </figure>
          <div>
            <div>
              <h2 className="text-2xl">{templateData.title}</h2>
              <p className="text-gray-500 text-sm">
                {templateData.description}
              </p>
              <p className="text-sm text-green-800 mt-2">
                by {templateData.author}
              </p>
            </div>
            <div className="mt-8 mb-6">
              <ButtonBookDemo />
            </div>
            <p className="text-gray-500">
              Detect patients that wil be transfer to ICU from COVID-19
              hospitalizations. Disclaimer
            </p>
            <div className="my-4">
              <h3 className="text-lg text-green-800">Training data</h3>
              <p className="text-sm text-gray-600">To be published</p>
            </div>
            <div className="my-4">
              <h3 className="text-lg text-green-800">Model performance</h3>
              <p>Sensitivity = 0.89 </p>
              <p>Specificity = 0.73</p>
              <p>AUC = 0.93 </p>
            </div>
            <div className="my-4">
              <h3 className="text-lg text-green-800">Piblications</h3>
              <p>To be published</p>
            </div>
            <div>
              <h5 className="text-gray-500 text-sm">Category</h5>
              <p className="bg-mainGray w-fit px-5 py-1 text-sm rounded-xl">
                {templateData.category}
              </p>
            </div>
          </div>
        </div>
        <div>
          <h4 className="text-2xl my-10">Similar Templates</h4>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-x-6 gap-y-12 justify-items-center">
            {templatesData
              .filter((template) => templateData.id !== template.id)
              .map(
                (template, index) =>
                  index < 4 && (
                    <TemplateItem
                      key={template.id}
                      image={template.image}
                      title={template.title}
                      description={template.description}
                      author={template.author}
                      id={template.id}
                    />
                  )
              )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Template;
