import React from "react";
import { ButtonBookDemo, Footer, Header } from "../../components";
import HeroImage from "../../assets/images/hero.png";
import MockupTurnYourData from "../../assets/images/mockup-turn-your-data.png";
import GoogleLogo from "../../assets/images/google-logo.png";
import NovartisLogo from "../../assets/images/novartis-logo.png";
import McgillLogo from "../../assets/images/mcgill-logo.png";
import ConcordiauLogo from "../../assets/images/concordiau-logo.png";
import SmartphoneMockup from "../../assets/images/smartphone-mockup.png";
import PoweredByData from "../../assets/images/powered-by-data.png";
import BuildWithEase from "../../assets/images/build-with-ease.png";
import StayConnected from "../../assets/images/stay-connected.png";
import LeafHealth from "../../assets/images/leaf-health.png";
import PipaImage from "../../assets/images/PIPA.png";
import PipedaImage from "../../assets/images/PIPEDA.png";
import HippaImage from "../../assets/images/HIPPA.png";
import ArppipsImage from "../../assets/images/ARPPIPS.png";
import InvestigationMalaria from "../../assets/images/investigation-malaria.png";
import InvestigationHippocrates from "../../assets/images/investigation-hippocrates.png";
import InvestigationOcular from "../../assets/images/investigation-ocular.png";
import InvestigationRespiratory from "../../assets/images/investigation-respiratory.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Home() {
  const { t } = useTranslation();
  return (
    <>
      <div className="px-2 m-4 rounded-3xl pb-6 bg-mainGray">
        <Header />
        <section className="max-w-5xl mx-auto mt-6">
          <h2 className="text-2xl md:text-4xl lg:text-6xl text-center leading-tight font-bold">
            {t("home.hero_text")}
          </h2>
          <p className="text-center text-lg md:text-xl max-w-3xl mx-auto mt-4 text-gray-600">
            {t("home.hero_description")}
          </p>
          <div className="block text-center mt-4">
            <ButtonBookDemo />
          </div>
          <figure className="w-full -mb-48 mt-8 md:mt-4">
            <img class="w-full md:w-3/5 mx-auto" src={HeroImage} alt="" />
          </figure>
        </section>
      </div>
      <div className="mt-3 px-4 mx-auto">
        <div className="w-full h-48 mb-10 rounded-2xl bg-gradient-to-r from-primary to-orange-300"></div>
      </div>
      <section className="max-w-5xl mx-auto">
        <p className="text-center text-gray-600 max-w-xl mx-auto text-base ">
          {t("home.hero_description_2")}
        </p>
        <div className="block md:flex px-3 md:px-4 mt-10">
          <div className="md:w-1/2">
            <h2 className="text-2xl md:text-5xl font-bold leading-tight mb-4 w-4/5">
              {t("home.title_turn_your_data")}
            </h2>
            <p className="text-gray-600">
              {t("home.description_turn_your_data")}
            </p>
          </div>
          <div className="md:w-1/2 md:text-right mt-4">
            <ButtonBookDemo />
          </div>
        </div>
        <figure className="w-full mt-10 px-4 md:px-2">
          <img
            src={MockupTurnYourData}
            className="rounded-3xl md:rounded-lg"
            alt=""
          />
        </figure>
      </section>
      <section className="max-w-5xl mx-auto">
        <p className="text-gray-600 text-center my-5 md:my-12">
          {t("home.look_organizations_have_used")}
        </p>
        <ul className="grid grid-cols-2 gap-y-4 md:grid-cols-4">
          <li>
            <figure className="w-1/2 mx-auto">
              <img src={GoogleLogo} />
            </figure>
          </li>
          <li>
            <figure className="w-1/2 mx-auto">
              <img src={NovartisLogo} />
            </figure>
          </li>
          <li>
            <figure className="w-1/2 mx-auto">
              <img src={McgillLogo} />
            </figure>
          </li>
          <li>
            <figure className="w-1/2 mx-auto">
              <img src={ConcordiauLogo} />
            </figure>
          </li>
        </ul>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-12 px-8">
          <div
            style={{ height: "fit-content" }}
            className="bg-purple-600 text-white px-8 py-5 rounded-3xl md:rounded-2xl md:mx-8"
          >
            <p className="text-xl mb-6">
              “{t("home.testimonials.testimonial_1.description")}”
            </p>
            <p className="text-sm">
              {t("home.testimonials.testimonial_1.name")} /{" "}
              {t("home.testimonials.testimonial_1.organization")}
            </p>
            <figure className="-mb-48 hidden md:block">
              <img src={SmartphoneMockup} />
            </figure>
          </div>
          <div>
            <div className="my-8 text-lg">
              <p>
                <span className="block md:inline" style={{ color: "#12CCE5" }}>
                  {t("home.testimonials.testimonial_2.description_1")}
                </span>{" "}
                {t("home.testimonials.testimonial_2.description_2")}
              </p>
              <div className="flex items-center mt-2">
                <figure className="w-1/5">
                  <img src={NovartisLogo} />
                </figure>
                <div className="text-gray-600 text-xs ml-2">
                  <p className="inline md:block">
                    {t("home.testimonials.testimonial_2.name")}
                  </p>
                  <p className="inline md:hidden">
                    , {t("home.testimonials.testimonial_2.organization")}
                  </p>
                  <p className="hidden md:block">
                    {t("home.testimonials.testimonial_2.role")} -{" "}
                    {t("home.testimonials.testimonial_2.organization")}
                  </p>
                </div>
              </div>
            </div>
            <div className="my-8 text-lg">
              <p>
                <span className="block md:inline" style={{ color: "#FF6C44" }}>
                  “{t("home.testimonials.testimonial_3.description_1")}
                </span>{" "}
                {t("home.testimonials.testimonial_3.description_2")}”
              </p>
              <div className="flex items-center mt-2">
                <figure>Logo person</figure>
                <div className="text-gray-600 text-xs ml-2">
                  <p className="inline md:block">
                    {t("home.testimonials.testimonial_3.name")}
                  </p>
                  <p className="inline md:hidden">
                    {t("home.testimonials.testimonial_3.organization")}
                  </p>
                  <p className="hidden md:block">
                    {t("home.testimonials.testimonial_3.role")},{" "}
                    {t("home.testimonials.testimonial_3.organization")}
                  </p>
                </div>
              </div>
            </div>
            <div className="my-8 text-lg">
              <p>
                <span className="block" style={{ color: "#887EF9" }}>
                  “{t("home.testimonials.testimonial_4.description_1")}
                </span>
                {t("home.testimonials.testimonial_4.description_2")}”
              </p>
              <div className="flex items-center mt-2">
                <figure>Logo Carmen</figure>
                <div className="text-gray-600 text-xs ml-2">
                  <p className="inline md:block">
                    {t("home.testimonials.testimonial_4.name")}{" "}
                  </p>
                  <p className="inline md:hidden">
                    {t("home.testimonials.testimonial_4.organization")}
                  </p>
                  <p className="hidden md:block">
                    {t("home.testimonials.testimonial_4.organization")} -{" "}
                    {t("home.testimonials.testimonial_4.role")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-5xl mx-4 md:mx-auto px-10 py-6 rounded-2xl md:rounded-md bg-mainGray">
        <div className="flex mt-10">
          <div className="w-full md:w-1/2">
            <p className="mb-4 text-xs" style={{ color: "#12CCE5" }}>
              {t("home.how_it_works.step_1.header")}
            </p>
            <h2 className="text-2xl md:text-4xl font-bold">
              {t("home.how_it_works.step_1.title")}
            </h2>
            <p className="text-gray-600 mt-4">
              {t("home.how_it_works.step_1.description")}
            </p>
          </div>
          <figure className="w-1/2 hidden md:block">
            <img className="w-3/5 mx-auto" src={PoweredByData} />
          </figure>
        </div>
        <div className="flex mt-10">
          <figure className="w-1/2  hidden md:block">
            <img className="w-3/5 mx-auto" src={BuildWithEase} />
          </figure>
          <div className="w-full md:w-1/2">
            <p className="mb-4 text-xs" style={{ color: "#FF6C44" }}>
              {t("home.how_it_works.step_2.header")}
            </p>
            <h2 className="text-2xl md:text-4xl font-bold">
              {t("home.how_it_works.step_2.title")}
            </h2>
            <p className="text-gray-600 mt-4">
              {t("home.how_it_works.step_2.description")}
            </p>
          </div>
        </div>
        <div className="flex mt-10">
          <div className="w-full md:w-1/2">
            <p className="mb-4 text-xs" style={{ color: "#887EF9" }}>
              {t("home.how_it_works.step_3.header")}
            </p>
            <h2 className="text-2xl md:text-4xl font-bold">
              {t("home.how_it_works.step_3.title")}
            </h2>
            <p className="text-gray-600 mt-4">
              {t("home.how_it_works.step_3.description")}
            </p>
          </div>
          <figure className="w-1/2 hidden md:block">
            <img className="w-3/5 mx-auto" src={StayConnected} />
          </figure>
        </div>
      </section>
      <section className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 mt-12 px-8 mb-14">
        <div>
          <p className="mb-16">#madewithark</p>
          <h2 className="text-2xl md:text-4xl font-bold leading-tight mb-4">
            {t("home.browse_templates.title")}
          </h2>
          <p className="text-gray-600">
            {t("home.browse_templates.description")}
          </p>
          <Link
            to="/templates"
            className="inline-block mt-12 shadow-2xl border text-sm font-bold border-gray-100 rounded-xs px-8 py-2"
          >
            {t("buttons.explore_templates")}
          </Link>
        </div>
        <div className="mt-16">
          <div className="flex mb-5 ml-36">
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-1.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-2.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-3.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-4.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-5.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-6.png")}
              />
            </figure>
          </div>
          <div className="flex mb-5 ml-24">
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-7.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-8.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-9.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-10.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-11.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-12.png")}
              />
            </figure>
          </div>
          <div className="flex mb-5 ml-12">
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-13.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-14.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-15.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-16.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-17.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-18.png")}
              />
            </figure>
          </div>
          <div className="flex mb-5">
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-19.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-20.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-21.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-22.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-23.png")}
              />
            </figure>
            <figure className="w-16 h-16 flex-shrink-0 ml-5 shadow-md">
              <img
                src={require("../../assets/images/icons-templates/IMAGE-24.png")}
              />
            </figure>
          </div>
        </div>
      </section>
      <section className="text-center pt-16 px-4 bg-mainGray">
        <div className="max-w-5xl mx-auto">
          <figure>
            <img src={LeafHealth} className="mx-auto mb-4" />
          </figure>
          <h3 className="font-bold text-2xl">
            {t("home.security_privacy_standards.title")}
          </h3>
          <p className="text-sm text-gray-600 mt-6">
            {t("home.security_privacy_standards.description")}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-7 px-8 mt-10">
            <div className="flex items-center border border-gray-200 rounded-lg px-2 lg:px-5 py-3 text-left">
              <figure className="w-2/5 lg:w-1/5 mr-4">
                <img src={HippaImage} />
              </figure>
              <div>
                <p>{t("home.security_privacy_standards.standard_1.title")}</p>
                <p>
                  {t("home.security_privacy_standards.standard_1.description")}
                </p>
              </div>
            </div>
            <div className="flex items-center border border-gray-200 rounded-lg px-2 lg:px-5 py-3 text-left">
              <figure className="w-2/5 lg:w-1/5 mr-4">
                <img src={PipaImage} />
              </figure>
              <div>
                <p>{t("home.security_privacy_standards.standard_2.title")}</p>
                <p>
                  {t("home.security_privacy_standards.standard_2.description")}
                </p>
              </div>
            </div>
            <div className="flex items-center border border-gray-200 rounded-lg px-2 lg:px-5 py-3 text-left">
              <figure className="w-2/5 lg:w-1/5 mr-4">
                <img src={PipedaImage} />
              </figure>
              <div>
                <p>{t("home.security_privacy_standards.standard_3.title")}</p>
                <p>
                  {t("home.security_privacy_standards.standard_3.description")}
                </p>
              </div>
            </div>
            <div className="flex items-center border border-gray-200 rounded-lg px-2 lg:px-5 py-3 text-left">
              <figure className="w-2/5 lg:w-1/5 mr-4">
                <img src={ArppipsImage} />
              </figure>
              <div>
                <p>{t("home.security_privacy_standards.standard_4.title")}</p>
                <p>
                  {t("home.security_privacy_standards.standard_4.description")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto text-left mt-32">
          <div className="block md:flex">
            <div className="mb-8 md:mb-0">
              <h3 className="font-bold text-3xl">{t("home.research.title")}</h3>
              <p className="text-gray-600 text-sm mt-2">
                {t("home.research.description")}
              </p>
            </div>
            <div
              style={{ gridTemplateColumns: "210px 210px 210px 210px" }}
              className="grid gap-x-4 overflow-x-scroll"
            >
              <a
                href="https://app.hubspot.com/documents/8676854/view/124578551?accessId=a29207"
                target="_blank"
                style={{ backgroundColor: "#20205A" }}
                className="w-full text-sm text-left text-white border rounded-xl overflow-hidden"
              >
                <figure className="w-full">
                  <img
                    className="h-44 w-full object-cover"
                    src={InvestigationMalaria}
                  />
                </figure>
                <p className="mx-4 mt-3">
                  {t("home.research.research_1.title")}
                </p>
                <p className="mx-4 mt-2 mb-6">
                  {t("home.research.research_1.date")}
                </p>
              </a>
              <a
                style={{ backgroundColor: "#20205A" }}
                className="w-full text-sm text-left text-white border rounded-xl overflow-hidden"
                href="https://app.hubspot.com/documents/8676854/view/229840937?accessId=98e1f7"
                target="_blank"
              >
                <figure className="w-full">
                  <img
                    className="h-44 w-full object-cover"
                    src={InvestigationOcular}
                  />
                </figure>
                <p className="mx-4 mt-3">
                  {t("home.research.research_2.title")}
                </p>
                <p className="mx-4 mt-2 mb-6">
                  {t("home.research.research_2.date")}
                </p>
              </a>
              <a
                href="https://app.hubspot.com/documents/8676854/view/234345503?accessId=df46ac"
                target="_blank"
                style={{ backgroundColor: "#20205A" }}
                className="w-full text-sm text-left text-white border rounded-xl overflow-hidden"
              >
                <figure className="w-full">
                  <img
                    className="h-44 w-full object-cover"
                    src={InvestigationRespiratory}
                  />
                </figure>
                <p className="mx-4 mt-3">
                  {t("home.research.research_3.title")}
                </p>
                <p className="mx-4 mt-2 mb-6">
                  {t("home.research.research_3.date")}
                </p>
              </a>
              <a
                href="https://app.hubspot.com/documents/8676854/view/265632232?accessId=01a4b4"
                target="_blank"
                style={{ backgroundColor: "#20205A" }}
                className="w-full text-sm text-left text-white border rounded-xl overflow-hidden"
              >
                <figure className="w-full">
                  <img
                    className="h-44 w-full object-cover"
                    src={InvestigationHippocrates}
                  />
                </figure>
                <p className="mx-4 mt-3">
                  {t("home.research.research_4.title")}
                </p>
                <p className="mx-4 mt-2 mb-6">
                  {t("home.research.research_4.date")}
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto mt-32">
          <h2 className="text-3xl md:text-6xl text-center font-bold">
            {t("home.hero_text")}
          </h2>
          <p className="text-center text-lg max-w-3xl mx-auto mt-4 text-gray-600">
            {t("home.hero_description")}
          </p>
          <div className="block text-center mt-4 mb-8 md:mb-0">
            <ButtonBookDemo />
          </div>
          <figure className="hidden md:block w-3/5 -mb-20 mx-auto">
            <img src={HeroImage} />
          </figure>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
