import React from "react";
import { Footer, Header, TemplateItem } from "../../components";
import { templatesData } from "../../utils/templatesData";

function Templates() {
  const [category, setCategory] = React.useState("all");
  const [templates, setTemplates] = React.useState(templatesData);

  const handleFilter = (category) => {
    setCategory(category);
    if (category === "all") {
      setTemplates(templatesData);
    } else {
      setTemplates(
        templatesData.filter((template) => template.category === category)
      );
    }
  };
  return (
    <>
      <div className="bg-mainGray px-2 m-4 rounded-3xl pb-3">
        <Header />
      </div>
      <section className="max-w-5xl mx-auto mb-16 block px-8 lg:px-0 md:flex">
        <div className="text-sm md:w-1/5 mt-14">
          <h4 className="font-bold uppercase mb-2">Category</h4>
          <ul className="text-gray-500 grid grid-cols-2 justify-items-center mb-4 md:block">
            <li
              className="my-2 cursor-pointer"
              style={{ fontWeight: category === "all" && 600 }}
              onClick={() => handleFilter("all")}
            >
              All Templates
            </li>
            <li
              className="my-2 cursor-pointer"
              style={{ fontWeight: category === "ophthalmology" && 600 }}
              onClick={() => handleFilter("ophthalmology")}
            >
              Ophthalmology
            </li>
            <li
              className="my-2 cursor-pointer"
              style={{ fontWeight: category === "respiratory" && 600 }}
              onClick={() => handleFilter("respiratory")}
            >
              Respiratory
            </li>
            <li
              className="my-2 cursor-pointer"
              style={{ fontWeight: category === "oncology" && 600 }}
              onClick={() => handleFilter("oncology")}
            >
              Oncology
            </li>
            <li
              className="my-2 cursor-pointer"
              style={{
                fontWeight: category === "hospital optimization" && 600,
              }}
              onClick={() => handleFilter("hospital optimization")}
            >
              Hospital optimization
            </li>
            <li
              className="my-2 cursor-pointer"
              style={{ fontWeight: category === "parasitary" && 600 }}
              onClick={() => handleFilter("parasitary")}
            >
              Parasitary
            </li>
          </ul>
        </div>
        <div className="ml-8 md:w-4/5">
          <h2 className="font-bold text-xl mb-6">Arkangel AI Apps</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-12 justify-items-center">
            {templates.map(({ image, title, description, id, author }) => (
              <TemplateItem
                image={image}
                title={title}
                description={description}
                id={id}
                author={author}
              />
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Templates;
