import React, { useState } from "react";
import {
  BookDemoModal,
  ButtonBookDemo,
  Footer,
  Header,
} from "../../components";
import PricingBase from "../../assets/images/pricing-base.png";
import PricingGrowth from "../../assets/images/pricing-growth.png";
import PricingPro from "../../assets/images/pricing-pro.png";
import BannerPricing from "../../assets/images/banner-pricing.png";
import { useTranslation } from "react-i18next";
import Accordion from "../../utils/Accordion";

const questionsES = [
  {
    question:
      "Cuando actualizo mi plan, ¿se actualizan todas mis aplicaciones de AI-health?",
    answer:
      "Sí, usted actualiza su cuenta globalmente: comience con el plan que le convenga y actualícelo a medida que necesite más funciones o capacidad.",
  },
  {
    question: "¿Qué sucede si reduzco mi cuenta o quiero cancelar mi licencia?",
    answer:
      "Si baja de categoría o cancela su licencia, se le abonará el tiempo no utilizado de ese mes o año para que lo utilice después.",
  },
  {
    question: "¿Qué es una tasa de predicción?",
    answer:
      "Una tasa de predicción es el coste de realizar una predicción en cualquier aplicación de AI-health que tenga en su cuenta.",
  },
  {
    question: "¿Qué es un KAM?",
    answer:
      "Un KAM o un gestor de cuentas clave, es un individuo a cargo de la planificación y la gestión de una asociación mutuamente beneficiosa entre su organización y Arkangel AI. Ella o él será su persona de contacto en caso de que necesite algo y se encargará de que usted tenga exito.",
  },
  {
    question: "¿Qué es el transfer learning?",
    answer:
      "El transfer learning (aprendizaje de transferencia) le permite a su organización aprovechar el conocimiento de algoritmos anteriores en un área similar a la suya mientras se personaliza con su propio conjunto de datos.",
  },
  {
    question: "¿Qué es un algoritmo pre-entrenado?",
    answer:
      "Los algoritmos pre-entrenados son modelos tipo plantilla que ofrecemos a nuestros clientes para que prueben el poder de la IA de primera mano. Puede empezar utilizando uno de estos algoritmos para aportar valor, recopilar datos y luego crear su propio algoritmo.",
  },
  {
    question: "¿Qué es una herramienta de etiquetado de imágenes?",
    answer:
      "Es una herramienta tipo ‘arrastrar y soltar’ que ofrecemos a nuestros clientes para anotar sus datos en bruto y convertirlos en aplicaciones de IA.",
  },
  {
    question: "¿Qué es el despliegue local?",
    answer:
      "El despliegue local significa que su aplicación de AI-salud vivirá en sus servidores locales y no tendrá que enviar ningún dato a la nube. Arkangel AI generará un paquete que puede ser instalado remotamente.",
  },
  {
    question: "¿Qué es un despliegue de socios?",
    answer:
      "Tenemos una creciente red de socios para desplegar nuestros algoritmos, muchos de ellos pueden estar ya instalados en su organización como una historia clínica electrónica, herramientas de visualización de radiología, entre otros. Esto le permite acelerar el despliegue en su organización.",
  },
  {
    question: "¿Qué es un gestor de éxito de la IA?",
    answer:
      "Es un experto en IA al que puede consultar lo que necesite volver un éxito sus aplicaciones de IA en su organización. Piense en él o ella como su experto de cabecera en la transformación de IA en su organización de salud.",
  },
  {
    question: "¿Qué incluye el soporte de implementación?",
    answer:
      "Ofrecemos 20 horas al mes de soporte de implementación a todos nuestros socios en el plan pro. Esto incluye el trabajo práctico de los expertos de nuestro equipo para ponerlo en marcha de la forma más rápida y precisa posible.",
  },
  {
    question:
      "¿Ofrecen descuentos para instituciones académicas o sin ánimo de lucro?",
    answer:
      "Sí, por favor póngase en contacto con nosotros en hola@arkangel.ai, describiendo el proyecto que desea construir con Arkangel AI. ",
  },
  {
    question:
      "¿Cómo puedo comprar Arkangel AI como revendedor o distribuidores?",
    answer:
      "Actualmente no tenemos un programa formal de revendedores, pero trabajamos con organizaciones seleccionadas. Si usted está interesado, por favor, póngase en contacto con hola@arkangel.ai.",
  },
  {
    question: "¿Cuáles son las condiciones de pago?",
    answer:
      "Arkangel AI funciona como una suscripción SaaS que puede ser adquirida sobre una base mensual o anual. Los suscriptores anuales se facturan una vez al año por adelantado, los suscriptores mensuales se facturan cada mes por adelantado.",
  },
  {
    question: "¿Puedo solicitar un presupuesto?",
    answer: "Sí. Para solicitar un presupuesto escribanos a hola@arkangel.ai",
  },
];

const questionsEN = [
  {
    question: "When I upgrade my plan, are all of my AI-health apps upgraded?",
    answer:
      "Yes, you upgrade your account globally—start with the plan that suits you and upgrade it as you need more features or capacity.",
  },
  {
    question:
      "What happens if I downgrade my account or want to stop my license?",
    answer:
      "If you downgrade or cancel your license you will be credited for unused time of that given month or year to use it after.",
  },
  {
    question: "What's a prediction fee?",
    answer:
      "A prediction fee is the cost to run a prediction on any AI-health App you have in your account.",
  },
  {
    question: "What's a KAM?",
    answer:
      "A KAM or a Key account management is an individual in charge of planning and managing a mutually beneficial partnership between your organization and Arkangel AI. She or he will be your go to person in case you need anything.",
  },
  {
    question: "What's transfer learning?",
    answer:
      "Transfer learning is a capability that allows your organization to leverage the knowledge of previous algorithms while customizing it with your own dataset.",
  },
  {
    question: "What's a pre-trained algorithm?",
    answer:
      "Pre-trained algorithms are template models we offer to our clients to test the power of AI firsthand. You can start by using one of these algorithms to provide value, collect data and then create your own algorithm.",
  },
  {
    question: "What's an image labeling tool?",
    answer:
      "It's a drag-and-drop tool we offer our clients to annotate their raw data to turn it into AI apps.",
  },
  {
    question: "What's On-premise deploy?",
    answer:
      "On-premise deploy means that your AI-health app will live in your local servers and you wont need to send any data to the cloud. Arkangel AI will generate a package that can be remotely installed.",
  },
  {
    question: "What's a Partner deploy?",
    answer:
      "We have a growing network of partners to deploy our algorithms, many of them may be already installed in your organization as an electronic medical record, radiology visualization tools, among others. This allows you to accelerate deployment through your organization.",
  },
  {
    question: "What's an AI-success manager?",
    answer:
      "She or he is an AI-expert you can consult to better adopt your AI-apps within your organization. Think of her or him as your go to expert in AI-health transformation.",
  },
  {
    question: "What’s included in the Implementation support?",
    answer:
      "We offer 20 hours per month of implementation support to all of our partners in the pro plan. This includes hands-on work from experts from our team to get you going as fast and accurate as possible.",
  },
  {
    question: "Do you offer discounts for academia or non-profits?",
    answer:
      "Yes, please reach out to us at hola@arkangel.ai with the project you want to build with Arkangel AI. ",
  },
  {
    question: "How do I purchase Arkangel AI as a Reseller or distributors",
    answer:
      "We do not currently have a formal reseller program, but we work with selected organizations. If you are interested please reach out to hola@arkangel.ai.",
  },
  {
    question: "What are the payment terms?",
    answer:
      "Arkangel AI works as a SaaS subscription that can be purchased on a monthly or annual basis. Annual subscribers are billed once a year upfront, monthly subscribers are billed every month upfront.",
  },
  {
    question: "Can I request a quote? ",
    answer: "Yes! You can request a quote at hola@arkangel.ai",
  },
];

function Pricing() {
  const { i18n, t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const [value, setValue] = useState(true);

  const [priceOutput] = useState({
    plan1: {
      false: ["2,500", "/month"],
      true: ["2,250", "/month billed yearly"],
    },
    plan2: {
      false: ["7,500", "/month"],
      true: ["6,750", "/month billed yearly"],
    },
    plan3: {
      false: ["15,000", "/month"],
      true: ["13,500", "/month billed yearly"],
    },
  });
  return (
    <>
      <div
        style={{ backgroundColor: "#f8f8f8" }}
        className="m-4 px-2 rounded-3xl"
      >
        <Header />
        <h1 className="max-w-5xl mx-auto text-center text-5xl font-bold pt-16 pb-20">
          {t("header.pricing")}
        </h1>
      </div>
      <section className="max-w-5xl mx-auto">
        <div className="flex text-right justify-end">
          <div
            className="flex justify-center max-w-xs mb-16"
            data-aos="zoom-y-out"
            data-aos-delay="300"
          >
            <div className="relative flex w-full mx-6 p-1 bg-gray-200 rounded">
              <span
                className="absolute inset-0 m-1 pointer-events-none"
                aria-hidden="true"
              >
                <span
                  className={`absolute inset-0 w-1/2 bg-white rounded shadow transform transition duration-150 ease-in-out ${
                    value ? "translate-x-0" : "translate-x-full"
                  }`}
                ></span>
              </span>
              <button
                className={`flex relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${
                  !value && "text-gray-500"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setValue(true);
                }}
              >
                {t("pricing.yearly")}{" "}
                <span className="text-green-500"> -10%</span>
              </button>
              <button
                className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${
                  value && "text-gray-500"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setValue(false);
                }}
              >
                {t("pricing.monthly")}
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 px-6 gap-y-4 lg:px-0 md:grid-cols-3 gap-x-4">
          <div className="border px-7 py-4 rounded-3xl">
            <figure>
              <img src={PricingBase} />
            </figure>
            <p className="text-sm my-2 text-orange-600">Arkangel AI Base</p>
            <div className="flex">
              <div className="mr-6">
                <p className="text-2xl text-orange-600">
                  ${priceOutput.plan1[value][0]}
                </p>
                <p className="text-gray-600 text-sm">
                  {t("pricing.per_month")}
                </p>
              </div>
              <div className="text-sm">
                <p>+ $1,00</p>
                <p>{t("pricing.prediction_fee")}</p>
              </div>
            </div>
            <button
              onClick={() => setShowModal(true)}
              className="border bg-orange-600 text-white font-bold text-sm w-full py-3 rounded-lg mb-4"
            >
              {t("buttons.book_a_demo")}
            </button>
            <ul className="text-sm">
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.train_unlimited_models")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.deploy_unlimited_models")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.use_pretrained_algorithms")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.transfer_learning_available")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.support_24_hours")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.shared_kam")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.api_access")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.arkangel_ai_co-branding")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.one_staff_account")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_base.image_labeling_tool")}
              </li>
            </ul>
          </div>
          <div className="border px-7 py-4 rounded-3xl">
            <figure>
              {" "}
              <img src={PricingGrowth} />
            </figure>
            <p className="text-sm my-2 t text-cyan-500">Arkangel AI Growth</p>
            <div className="flex">
              <div className="mr-6">
                <p className="text-2xl t text-cyan-500">
                  ${priceOutput.plan1[value][0]}
                </p>
                <p className="text-gray-600 text-sm">
                  {t("pricing.per_month")}
                </p>
              </div>
              <div className="text-sm">
                <p>+ $1,00</p>
                <p>{t("pricing.prediction_fee")}</p>
              </div>
            </div>
            <button
              onClick={() => setShowModal(true)}
              className="border bg-cyan-500 text-white font-bold text-sm w-full py-3 rounded-lg mb-4"
            >
              {t("buttons.book_a_demo")}
            </button>
            <ul className="text-sm">
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_growth.everything_in_base")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_growth.on-premise_deploy")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_growth.partner_network_deploy")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_growth.no_arkangel_ai_branding")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_growth.dedicated_kam")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_growth.support_1_hour")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_growth.five_staff_accounts")}
              </li>
            </ul>
          </div>
          <div className="border px-7 py-4 rounded-3xl">
            <figure>
              {" "}
              <img src={PricingPro} />
            </figure>
            <p className="text-sm my-2 text-gray-700">Arkangel AI Pro</p>
            <div className="flex">
              <div className="mr-6">
                <p className="text-2xl text-gray-700">
                  ${priceOutput.plan1[value][0]}
                </p>
                <p className="text-gray-600 text-sm">
                  {t("pricing.per_month")}
                </p>
              </div>
              <div className="text-sm">
                <p>+ $1,00</p>
                <p>{t("pricing.prediction_fee")}</p>
              </div>
            </div>
            <button
              onClick={() => setShowModal(true)}
              className="border bg-gray-700 text-white font-bold text-sm w-full py-3 rounded-lg mb-4"
            >
              {t("buttons.book_a_demo")}
            </button>
            <ul className="text-sm">
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_pro.everything_in_growth")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_pro.implementation_support")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_pro.analytics_dashboard")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_pro.support_30_minutes")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_pro.dedicated_ai-success_manager")}
              </li>
              <li className="my-2">
                + &nbsp; {t("pricing.pricing_pro.ten_staff_accounts")}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-mainGray">
        <div className="max-w-5xl mx-auto px-6 lg:px0 text-sm py-12 list-none">
          <h3 className="text-4xl pb-8">{t("pricing.pricing_questions")}</h3>

          {i18n.language === "en"
            ? questionsEN.map(({ question, answer }) => (
                <Accordion title={question}>{answer}</Accordion>
              ))
            : questionsES.map(({ question, answer }) => (
                <Accordion title={question}>{answer}</Accordion>
              ))}
        </div>
      </section>
      <section className="max-w-5xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold mt-16 mx-auto leading-tight max-w-md">
          Make your own AI-health app.
        </h2>
        <p className="text-gray-600 my-4">
          Make it in minutes and without writing a line of code.
        </p>
        <ButtonBookDemo />
        <figure className="mb-12">
          <img src={BannerPricing} />
        </figure>
      </section>
      <Footer />
      <BookDemoModal show={showModal} setShow={setShowModal} />
    </>
  );
}

export default Pricing;
